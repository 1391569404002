import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import Button from '@mui/material/Button'
import { grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles'


export const DiscoverButton = styled(Button)({
  textTransform: 'none',
  
      backgroundColor: "#191919",
      zIndex: 2,
      width: '110px',
      color: 'white',
      height: '35px',
      borderRadius: '25px',
      fontSize: '0.7rem',

      '&:hover' : {
        backgroundColor: grey[700]
      }
   
  })


export const sxStyle = {

  mainContainer: { 
    display:"flex",
    justifyContent:"start",
    flexDirection:"column",
    

    marginTop: {
      xs: '50px',
      sm: '150px',
      md: '150px',
      lg: '150px',
      xl: '150px'
    }

},
secondaryContainer: {
  display:"flex",
  flexDirection: {
    xs: 'column',
    sm: 'row',
    md: 'row',
    lg: 'row',
    xl: 'row'
  }

},
gatsbyImageDataContainer: {
  width: '180px',
  height: '180px'
},
textContainer: {
  padding: '80px 10px 10px 10px',
  flexDirection: {
    xs: '70vw',
    sm: '30vw',
    md: '30vw',
    lg: '30vw',
    xl: '30vw'
  }
 
  

}

}

const useStyles = makeStyles((theme) => {

 return({


   pageContainer: {

   
     marginLeft: '20px',
     marginRight: '20px',
     marginTop: '20px',
     [theme.breakpoints.up('sm')]: {
       marginLeft: '200px',
       marginRight: '200px',
       marginTop: '50px'
     }

   },


   frameContainer: {
       display:"flex",
       flexDirection:"row",
       justifyContent:"center",
       width: 'calc(95vw)',
       height: 'calc(95vw*0.575)',
       [theme.breakpoints.up('md')]: {
         width: 'calc(70vw)',
         height: 'calc(70vw*0.575)'
       }
     },
   frame: {
       width: "100%",
       [theme.breakpoints.up('md')]: {
           width: '100%',
           height: '100%'
       }
   },

   gatsbyImageDataContainer: {
      width: '180px',
      height: '180px'
   },

   secondaryContainer: {
       display:"flex",
       flexDirection:'row'
   },
   textContainer: {
       padding: '80px 10px 10px 10px',
       width: '30vw',
   
   },
   mainContainer: { 
       marginTop: '50px',
       display:"flex",
       justifyContent:"start",
       flexDirection:"column",
   
   [theme.breakpoints.up('sm')]: {
       marginTop: '150px'
   } }

})

})
export default useStyles

