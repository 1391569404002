import React from "react"
import Box from '@mui/material/Box'


import { GatsbyImage } from "gatsby-plugin-image"
import Typography from '@mui/material/Typography'
import useStyles, { DiscoverButton, sxStyle } from './formateurStyle'
import { navigate } from "gatsby-link"

import useMediaQuery from '@mui/material/useMediaQuery'
import ShowLess from '../general/showLess'
import { grey } from '@mui/material/colors';




const SectionFormateur = ({ data }) => {

    const pageEvent = data.pageEvent ? data.pageEvent : data.originalPageEvent


    const formateur = pageEvent.children.find(child => child.nom)
    const matches = useMediaQuery(theme => theme.breakpoints.down('md'))







    let gatsbyImageData = null
    if (formateur?.children.length) {
        gatsbyImageData = formateur.children.find(image => image.original?.src.includes('originalCropped'))?.gatsbyImageData
        if (!gatsbyImageData)
            gatsbyImageData = formateur.children.find(image => image.original?.src.includes('Thumb'))?.gatsbyImageData


    }


    const classes = useStyles()

    if (!formateur)
        return null

    return (
        <Box sx={sxStyle.mainContainer}>


            <Box width="70vw" borderBottom={`0.5px solid ${grey[400]}`} paddingBottom="20px" marginBottom="80px">
                <Typography variant="h2" style={{ fontSize: '1.8rem' }}>
                    Le conférencier
                </Typography>
            </Box>
            <Box sx={sxStyle.secondaryContainer} >
                <Box sx={sxStyle.gatsbyImageDataContainer}>
                <GatsbyImage
                    onClick={() => {
                        navigate(formateur.fields.slug)
                    }}
                    image={gatsbyImageData}
                    alt={formateur.nom ? formateur.nom : ''}
                    loading="lazy"
                    style={{ zIndex: 2, cursor: 'pointer' }}
                    

                />

                </Box>

            
                <Box sx={sxStyle.textContainer}>

                    <Typography style={{ fontWeight: "bold" }}>
                        {formateur?.prenom}{" "} {formateur?.nom}
                    </Typography>
                    <Box marginTop="20px" >
                        <ShowLess text={formateur.intro} showMaxSize={20} handleCutIf={matches} />


                    </Box>



                </Box>




            </Box>
            <Box width="100%" >
                <Box textAlign="right" marginRight="70px">
                    <DiscoverButton onClick={() => {
                        navigate(formateur.fields.slug)
                    }}
                    >
                        Découvrir
                    </DiscoverButton>

                </Box>


            </Box>
        </Box>)
}

export default SectionFormateur






