import React, {useRef}  from "react"

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import useStyles,  {sxStyle} from './sectionProgramStyle'
import {ButtonHandler} from '../general/showLess'
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import FrontOfficeTheme from '../../../style/globalTheme'
import cleanHtml from '../../../components/SVGIcons/helpers'
import { grey } from '@mui/material/colors';

const SectionProgram = ({ data }) => {

    const pageEvent = data.pageEvent ? data.pageEvent : data.originalPageEvent


    const maxWordSize = 80
    const smallTextLimit = 500
    
    const matches = useMediaQuery(theme => theme.breakpoints.down('md'))
    const [toggle, setToggle] = React.useState(false)
    const [hideButton, setHideButton] = React.useState(false)

    const divRef = useRef(null)

    React.useEffect(()=>{
        if (pageEvent .documentHtml && pageEvent .documentHtml.length <  maxWordSize){
            setHideButton(true)
        }
        if (data && !pageEvent .documentHtml)
        setHideButton(true)

    })

    const props = {
        cutHtml : toggle
    }

    const classes = useStyles(props)


    const htmlClass = (pageEvent?.documentHtml?.length > smallTextLimit) ? sxStyle(props).htmlContainer : sxStyle(props).htmlContainerShort

    
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={FrontOfficeTheme}>
           <Box sx={sxStyle().mainContainer} >
               <Box width="70vw" borderBottom={`0.5px solid ${grey[400]}`} paddingBottom="20px" marginBottom="30px">
                   <Typography variant="h2" style={{ fontSize: '1.8rem' }}>
                       Le programme
                   </Typography>

               </Box>
               
               <Box  sx={htmlClass} ref={divRef}>
                       <div sx={sxStyle().divHtmlContainer} dangerouslySetInnerHTML={{ __html: cleanHtml(pageEvent?.documentHtml)}} />
               </Box>
               {matches && !hideButton && <ButtonHandler toggle={toggle} setToggle={setToggle} />}



           </Box >
           </ThemeProvider>
        </StyledEngineProvider>
    );


}

export default SectionProgram