
import React, { useState } from 'react'
import { GatsbyImage } from "gatsby-plugin-image"

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { MiniButton } from '../../components/style/pages/mainFilterStyles'

import useStyles, {  webinarImageWidth, webinarImageHeight } from '../../components/style/pages/eventsSection'
import getDates from '../../helpers/dates'
import { grey } from '@mui/material/colors';

const imageWidth = webinarImageWidth
const imageHeight = webinarImageHeight


const WebinarSection = ({ events, title }) => {

    const [position, setposition] = useState(0)

    if (!events)
        return null


  
    const clickHandler = (mode) => (event) => {
        if (mode === 'leftArrow') {
            setposition(Math.max((position - 5), 0))
        }
        if (mode === 'rightArrow') {
            setposition(Math.min(position + 5, (events.length - events.length % 5)))
        }


    }


    return (
        <Box marginTop="60px" >
            <Box paddingTop="60px" borderTop={`0.5px solid ${grey[500]}`}>
            <Typography variant="h1">
            {title ? title : "Webinars"}

            </Typography>
     

            </Box  >
       
            <Box alignSelf="flex-end" marginTop="30px" display="flex" flexDirection="row" width="80px">
                <MiniButton>
                    <KeyboardArrowLeftIcon style={{ fontSize: '1.1rem' }} onClick={clickHandler('leftArrow')} />
                </MiniButton>
                <MiniButton>
                    <KeyboardArrowRightIcon style={{ fontSize: '1.1rem' }} onClick={clickHandler('rightArrow')} />
                </MiniButton>


            </Box>
            <Box id="webinar-container" width='80vw' display="flex" marginTop="30px" flexDirection="row" flexWrap="nowrap" overflow="hidden" justifyContent='start'>

                {events.map((event, index) => <SingleEvent key={index} index={index} event={event} position={position} />)}
            </Box>
        </Box>
    )
}

export default WebinarSection

const SingleEvent = ({ event, position, index }) => {


  

    const classes = useStyles()

    if (!event.children)
        return null



    const children = event.children
    const gatsbyImageData = children.find(image => image.gatsbyImageData)?.gatsbyImageData

    if (!gatsbyImageData)
        return null

    let margin = ''
    margin = '0 5px  0 5px'
    if (index === 0)
        margin = '0 5px 0 0'




    return (<Box display="flex"  flexDirection="column" style={{ transform: `translate(${-(imageWidth+10) * position}px, 0)`, transition: 'all 0.3s ease-in-out' }}>
        
        
       
        <Box  margin={margin} width={imageWidth + 'px'} height={imageHeight + 'px'} >
            <GatsbyImage
                image={gatsbyImageData}
                alt={event.title1 ? event.title1 : ''}
                loading="lazy"
                className={classes.webinarImage}
            />
      


        </Box>
        <Box marginTop="10px" margin="10px">
                <Typography style={{ color: 'black', fontSize: '0.7rem', fontWeight: 500 }}>
                    
                         {event.title1}
                </Typography>
                <Box marginTop="2px">

              
                <Typography style={{ color: grey[600], fontSize: '0.7rem', fontWeight: 500, lineHeight: '0.8rem' }}>
                    
                         {event.title2}
                </Typography>
                </Box>
                <Box marginTop="5px">
                    <Typography style={{fontSize: '0.6rem'}}>
                    {getDates(event.date).dateShort.split('/').slice(0,2).join('/')}{' à '}{getDates(event.date).time}h
                    </Typography>
               
                </Box>

            </Box>

    </Box>)
}