import React, { useContext, useEffect, useState, useCallback } from "react"
import { graphql } from 'gatsby'
import Box from '@mui/material/Box'
import Fade from '@mui/material/Fade';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import queryString from 'query-string'
import '../components/layout'
import { convert } from 'html-to-text'
import { fetchTransactions, fetchPromoCodes } from '../helpers/fetchers'
import { grey, orange } from '@mui/material/colors'
import * as rdd from 'react-device-detect'
import {remises} from '../helpers/generalData'

import publicIp from 'public-ip'
// import { Analytics } from 'aws-amplify'




import useMediaQuery from '@mui/material/useMediaQuery'

import Layout from "../components/layout"
import Seo from "../components/seo"

import SectionEvent from './components/events/sectionEvent'
import SectionProgram from './components/events/sectionProgram'
import SectionFormateur from './components/events/sectionFormateur'
import useStyles, {sxStyle} from './components/events/eventStyle'
import SectionOtherEvent from './components/events/sectionOtherEvent'


import FrontOfficeTheme from '../style/globalTheme'
import { Analytics } from 'aws-amplify'

import SubscriptionModal from '../workflows/subscriptionModal/main'
import { AuthContext } from '../context/general'
import { Typography } from "@mui/material"
import {DEBUG} from '../buildSpecs'






  export const Head = ({ location, params, data, pageContext }) => {
  
      const pageEvent = data.pageEvent ? data.pageEvent : data.originalPageEvent
      const slug = pageEvent.fields.slug
      const pageDescription = convert(pageEvent.documentHtml, {
        wordwrap: 130
      })
    
      const smallImageSlug = pageEvent.children.filter(child => child.ogImage)
        .filter(child => child.original?.src.includes('500'))
        .filter(child => child.original?.src.includes('L'))
        .find(child => child.original?.src.includes('jpg'))
        ?.original.src
    
      return (
        <Seo title={'conférence: '+ pageEvent.title1} slug={slug} description={pageDescription} image={smallImageSlug} data={data} />
              
        )
    
    }




export default function Events({ data }) {


  const matches = useMediaQuery(theme => theme.breakpoints.up('md'));
  const classes = useStyles()
  const [isMounted, setIsMounted] = useState(false)
  const [cognitoId, setCognitoId] = useState(null)
  const [hasSentRecord, setHasSentRecord] = useState(false)



  const { authState, authActions } = useContext(AuthContext)

  const [isInvValid, setIsInvValid] = useState(false)



  // KEY-HANDLER USED TO SHOW INFORMATION

  const [keySequence, setKeySequence] = useState('')
  const [showInfo, setShowInfo] = useState(false)

  const handleKeyPress = (event) => {
    let newKeySequence =  null
    if (keySequence.length < 7)
       newKeySequence = keySequence + event.key
      

    else
       newKeySequence = keySequence.substring(1, 7) + event.key
    
    setKeySequence(newKeySequence)

    if ((newKeySequence) == 'pekoia!')
      setShowInfo(true)
  }

  useEffect(() => {
    // attach the event listener
    document.addEventListener('keydown', handleKeyPress);

    // remove the event listener
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

  // END OF KEY-HANDLER USED TO SHOW INFORMATION









  
  useEffect(() => {



    if (!isMounted)
      setIsMounted(true)


    if (authState.user && ((authState.user.cognitoId != cognitoId))) {
      const getParsed = queryString.parse(window.location?.search)

      authActions.setParsed({...getParsed, clientId:authState.user.id})
      
    
      const cognitoId = authState.user.cognitoId
      fetchTransactions(cognitoId, authActions.setTransactions, authActions.setCards,  authActions.set_HasNeverBought, authActions.appendTransactions, authActions.setCredits, authActions.setCredits, authActions.setReplays, authActions.appendReplays)
      setCognitoId(authState.user.cognitoId)
      fetchPromoCodes(authActions.setCodePromos)
    }

  }, [authState])

  useEffect(() => {

    const getParsed = queryString.parse(window.location?.search)
   
    if (getParsed.shareKey && authState.parsed && !authState.parsed.shareKey)
    {

    authActions.setParsed({...getParsed})
    

    }



  }, [authState])


  useEffect(()=>{

    
   

        const timer = setTimeout(_ => {
        
       if ((process.env.NODE_ENV === "production") && !hasSentRecord) {


          publicIp.v4()
          .then(ip => {

            const attributes = {
              clientId: 'visitor',
              deviceType: rdd.deviceType,
              mobileModel: rdd.mobileModel,
              mobileVendor: rdd.mobileVendor,
              osName: rdd.osName,
              osVersion: rdd.osVersion,
              ip: ip
            }

            if (typeof window !== "undefined") {
              if (window.fbq != null) {
                window.fbq('track', 'eventPageVisit', attributes);
              }
            }

        

            Analytics.record({
              name: 'eventPageVisit',
              attributes: attributes
            })

          })

       }


     }, 1000)

    return () => {
      clearTimeout(timer)
    }



  }, [])


  const [showPromo, setShowPromo] = useState(false)




  // useEffect(() => {

  //   if (authState.parsed?.fetchResult){
  //     authState.parsed.fetchResult
  //     .then(data=>{
   
  //        setShowPromo(data)
  //     })
  //     .catch(err=>{
  //        console.log('ERR')
  //      })
     
  //   }

   



 


  //   if (process.env.NODE_ENV === "production") {




  //     if ((authState?.user?.id) && (!hasSentRecord)) {
    

  //       publicIp.v4()
  //         .then(ip => {

  //           const attributes = {
  //             clientId: authState.user.id,
  //             email: authState.user.attributes?.email,
  //             deviceType: rdd.deviceType,
  //             mobileModel: rdd.mobileModel,
  //             mobileVendor: rdd.mobileVendor,
  //             osName: rdd.osName,
  //             osVersion: rdd.osVersion,
  //             ip: ip
  //           }

  //           const eventData = {
  //             name: 'eventPageVisit',
  //             attributes: attributes
  //           }

       

  //           Analytics.record(eventData)


  //         })
  //         .catch(err => console.log(err))


  //       setHasSentRecord(true)




  //     }




   

  //   }



  // }, [authState])

  if (DEBUG){

    if (DEBUG == 1)
      return (<Box>DEBUG MODE</Box>)


  }


 if (!isMounted)
  return null

  if (!data)
    return null

  if (authState.showSubscriptionModal && !matches) {

    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={FrontOfficeTheme}>

          <Layout data={data}>
            <SubscriptionModal />
          </Layout>
        </ThemeProvider>
      </StyledEngineProvider>
    );

  }

 

  const pageEvent = data.pageEvent ? data.pageEvent : data.originalPageEvent

  const pageDescription = convert(pageEvent.documentHtml, {
    wordwrap: 130
  })



  const slug = pageEvent.fields.slug

  const imageSlugRaw = pageEvent.children.filter(child => child.ogImage)
    .filter(child => child.original?.src.includes('500'))
    .filter(child => child.original?.src.includes('L'))
    .find(child => child.original?.src.includes('jpg'))
    ?.original.src

    const smallImageSlug = pageEvent.children.filter(child => child.ogImage)
    .filter(child => child.original?.src.includes('500'))
    .filter(child => child.original?.src.includes('L'))
    .find(child => child.original?.src.includes('jpg'))
    ?.original.src


   




  //const imageSlug = imageSlugRaw[0] === '/' ? imageSlugRaw.slice(1, imageSlugRaw.length) : imageSlugRaw
  const imageSlug = imageSlugRaw










  
const remise = remises.find(remises => remises.cat ==authState?.parsed?.cat ) 

const isValid = authState?.parsed?.shareKey && (authState?.parsed?.shareKey != authState?.user?.id)



const isPromo = (pageEvent?.prix >= 5) && (((authState.parsed?.valid) && authState.hasNeverBought  && isValid) || (authState.user && !authState.user.id && authState.parsed?.valid))

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={FrontOfficeTheme}>

        <Layout data={data}>
         
          {data.pageEvent && <Fade in={showInfo}><Box padding='30px'>{data.pageEvent.fields.eventId}</Box></Fade>}
          
          { isPromo && <Box
            width="100%"
            color={grey[300]}
            bgcolor={orange[700]}
            padding= "30px"
          
          >  
          
          <Typography style={{fontSize: '0.9rem', fontWeight: 'bold'}}>
            
          Vous pouvez remercier votre parrain. Vous bénéficiez d'une remise de <span>{remise.amount}</span>€ sur cette conférence
          </Typography>
           </Box>}

          <SubscriptionModal/>



          <Box sx={sxStyle.mainContainer}>

          
      
             <SectionEvent data={data} authState={authState} isPromo={isPromo}/>
        
             <SectionProgram data={data} />
      
             <SectionFormateur data={data} />

     
            

        
          </Box>

        </Layout>
      </ThemeProvider>
    </StyledEngineProvider>
  );

}






export const pageQuery = graphql`
 query ($slug: String!){

  site {
    siteMetadata {
      url
    }
  }

  mainImage: imageSharp(original: {src: {regex: "/webinar/"}}) {
    gatsbyImageData
  }

  markdowns: allMarkdownRemark {
    nodes {
      html
      frontmatter {
        title
        position
        color
        slug
      }
    }
  }


  originalPageEvent: dbEvent (fields: {originalSlug: {eq: $slug}})
  {
     
    title1
    title2
    format
    outil
    attendingNb
    categories
    formateurId
    documentHtml
    nbMax
    duree
    prix
    date
    replayStatus
    fields
    {
      eventId
      slug
    }
    children {
      ... on DBFormateur {
        id
        nom
        prenom
        sanboxLemonwayId
        lemonwayId
        hashList
        intro
        fields{
          formateurId
          slug
        }
        children {
          ... on ImageSharp {
            gatsbyImageData
            original{

            src}
          }
        }
      }
      ... on ImageSharp {
        gatsbyImageData(
          quality: 100
          width: 1000
          

        )
        original {
          src
        }
        thumbnail: gatsbyImageData(
          width: 150
          quality: 100)

        ogImage: gatsbyImageData(
            width: 1000
            height: 630
            quality: 80
            placeholder: BLURRED
            transformOptions: {cropFocus: ATTENTION}
            )

      }

    }
  }
  

  pageEvent: dbEvent (fields: {slug: {eq: $slug}})
  {
     
    title1
    title2
    format
    outil
    attendingNb
    categories
    formateurId
    documentHtml
    nbMax
    duree
    prix
    date
    replayStatus
    fields
    {
      eventId
      slug
    }
    children {
      ... on DBFormateur {
        id
        nom
        prenom
        sanboxLemonwayId
        lemonwayId
        hashList
        intro
        fields{
          formateurId
          slug
        }
        children {
          ... on ImageSharp {
            gatsbyImageData
            original{

            src}
          }
        }
      }
      ... on ImageSharp {
        gatsbyImageData(
          quality: 100
          width: 1000
          

        )
        original {
          src
        }
        thumbnail: gatsbyImageData(
          width: 150
          quality: 100)

        ogImage: gatsbyImageData(
            width: 1000
            height: 630
            quality: 80
            placeholder: BLURRED
            transformOptions: {cropFocus: ATTENTION}
            )

      }

    }
  }

 }
`